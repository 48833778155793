import { defineStore } from "pinia";
import { Meta, ResponseType } from "~/types/general";
import { ToastType } from "~/types/general";
import { useTasksStore } from "~/store/tasks";
import { ManagementLocationsStatus } from "~/types/management-locations";
import { useStoresStore } from "./stores";
import { Store } from "~/types/stores";

// Management Locations Store
export const useManagementLocationsStore = defineStore(
  "management-locations",
  () => {
    // State
    const managementLocations = ref([]);
    const selectedRowsIds = ref([]);
    const baseTableKey = ref(0);
    const totalManagementLocations = ref(null);
    const totalPages = ref(null);
    const isOpenSidebar = ref(false);
    const meta = ref({} as Meta);
    const filters = ref({
      letter: "",
    });
    const statuses = ref([
      {
        name: "Declined",
        value: ManagementLocationsStatus.DECLINED,
      },
      {
        name: "Pending",
        value: ManagementLocationsStatus.PENDING,
      },
      {
        name: "Application",
        value: ManagementLocationsStatus.APPLICATION,
      },
      {
        name: "Active",
        value: ManagementLocationsStatus.ACTIVE,
      },
    ]);
    const page = ref(1);
    const perPage = ref(40);
    const pageTable = ref(1);
    const perPageTable = ref(10);
    const filtersOptions = ref({
      type: {
        type: "type",
        title: "Location Type",
        subtitle: "Leave blank to view all types",
        options: useStoresStore().locationTypes,
        chosenOptions: [],
      },
      categories: {
        type: "categories",
        title: "Location Category",
        subtitle: "Leave blank to view all categories",
        options: [],
        chosenOptions: [],
      },
      tags: {
        type: "tags",
        title: "Location Tag",
        subtitle: "Leave blank to view all tags",
        options: [],
        chosenOptions: [],
      },
      status: {
        type: "status",
        title: "Location Status",
        subtitle: "Leave blank to view all statuses",
        options: [
          {
            name: "Declined",
            value: "declined",
          },
          {
            name: "Pending",
            value: "pending",
          },
          {
            name: "Active",
            value: "active",
          },
        ],
        chosenOptions: [],
      },
    });
    const settingsSearchValue = ref("");

    // Actions
    async function getManagementLocations(
      payload?
    ): Promise<ResponseType<Store[]>> {
      const params = {
        page: page.value,
        limit: perPage.value,
        ...payload,
      };

      try {
        const response = await useVaniloApi("/stores", { params });
        return response as ResponseType<Store[]>;
      } catch (error) {
        useCatchError(error);
      }
    }

    async function importManagementLocations(payload?) {
      let response: any;
      const { $toast } = useNuxtApp();

      try {
        response = await useVaniloApi("/stores/import", {
          method: "POST",
          body: payload,
        });
      } catch (error) {
        $toast(`${error}`, {
          type: ToastType.ERROR,
          duration: 3000,
        });
        console.log(error);
      }

      return response;
    }

    async function exportManagementLocations(payload?) {
      let response: any;
      const { $toast } = useNuxtApp();

      try {
        response = await useVaniloApi("/stores/export", {
          method: "GET",
          params: payload,
        });
      } catch (error) {
        $toast(`${error}`, {
          type: ToastType.ERROR,
          duration: 3000,
        });
        console.log(error);
      }

      return response;
    }

    async function bulkUpdateManagementLocations(storesIds, updatesValue) {
      let response: any;
      let payload = {};
      const tasksStore = useTasksStore();

      if (updatesValue.type === "change-status") {
        payload["status"] = updatesValue.value;
      }
      if (
        updatesValue.type === "update-tag" ||
        updatesValue.type === "assign-category"
      ) {
        payload["taxonomies"] = updatesValue.value;
      }

      try {
        response = await useVaniloApi(`/stores/bulk-update`, {
          method: "POST",
          body: {
            ...(storesIds.length && { stores: storesIds }),
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
        console.log(error);
      }

      tasksStore.taskId = response.data.id;

      return response;
    }

    async function bulkNotifyManagementLocations(payload) {
      let response: any;

      try {
        response = await useVaniloApi(`/stores/bulk-notify`, {
          method: "POST",
          body: payload,
        });
      } catch (error) {
        console.log(error);
      }

      return response;
    }

    async function bulkDeleteManagementLocations(payload) {
      let response: any;
      try {
        response = await useVaniloApi(`/stores/bulk-delete`, {
          method: "POST",
          body: payload,
        });
      } catch (error) {
        console.log(error);
      }
      return response;
    }

    return {
      // State
      managementLocations,
      selectedRowsIds,
      baseTableKey,
      totalManagementLocations,
      totalPages,
      isOpenSidebar,
      meta,
      filters,
      statuses,
      page,
      perPage,
      pageTable,
      perPageTable,
      filtersOptions,
      settingsSearchValue,
      // Actions
      getManagementLocations,
      importManagementLocations,
      exportManagementLocations,
      bulkUpdateManagementLocations,
      bulkNotifyManagementLocations,
      bulkDeleteManagementLocations,
    };
  }
);
